<template>
  <el-container>
    <el-header>{{$locale.report_editor.header.title}}</el-header>
  </el-container>
</template>

<script>
export default {
  name: 'registry-history',
  props: {
    objectId: Number,
    recordId: Number
  },
  data () {
    return {
      deltas: []
    }
  },
  mounted () {
    // this.loadData()
  },
  methods: {
    async loadData () {
      this.deltas = []
      let data = await this.$http.get(`${this.$config.api}/historyservice/objects/${this.objectId}/records/${this.recordId}`)
      data.data.forEach((item) => {
        console.log(item)
        this.deltas = this.deltas.concat(this.parseDeltas(item, item.deltas))
      })
    },
    parseDeltas (snapshot, deltas = []) {
      return deltas.map((delta) => {
        delta.field = `${snapshot.fields[delta.field]} (${delta.field})`
        delta.event_type = this.$locale.registry.history.event_types[snapshot.event_type]
        delta.event_date = this.$moment(new Date(parseInt(snapshot.event_date.$date.$numberLong))).format('DD.MM.Y H:mm:ss')
        if (snapshot.user.id) {
          delta.event_user = `${snapshot.user.name} (${snapshot.user.id})`
        }

        if (Array.isArray(delta.before)) {
          delta.before = delta.before.map((item) => `${item.name} (${item.id})`).join(',')
        }
        if (Array.isArray(delta.after)) {
          delta.after = delta.after.map((item) => `${item.name} (${item.id})`).join(',')
        }

        return delta
      })
    }
  }
}
</script>

<style scoped>

</style>
